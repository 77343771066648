<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-12">
                <h4>
                  <span>  {{ `${page.id ? 'Update' : 'Add'}` }} page</span>
                </h4>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                <router-link :to="{name:'page'}">
                  Pages
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 form-group">
                    <v-text-field outlined dense label="Title" v-model="page.title"></v-text-field>
                    <span class="text-danger" v-if="$v.page.title.$error">This information is required</span>
                  </div>

                  <div class="col-12 form-group">
                    <label for class="">Content</label>
                    <ckeditor v-model="page.content"></ckeditor>

                    <span class="text-danger"
                          v-if="$v.page.content.$error">This information is required</span>
                  </div>

                  <div class="col-12 form-group">
                    <v-text-field outlined dense label="Seo title"
                                  v-model="page.seo_title"></v-text-field>
                  </div>
                  <div class="col-12 form-group">
                    <v-combobox
                            outlined
                            small-chips
                            dense
                            v-model="page.seo_keyword"
                            label="Seo keywords"
                            multiple
                    ></v-combobox>

                  </div>
                  <div class="col-12 form-group">
                    <v-textarea
                        outlined dense
                        name
                        label="Seo content"
                        cols="30"
                        rows="10"
                        v-model="page.seo_description"
                    ></v-textarea>
                  </div>

                  <div class="col-12 mt-4">
                    <v-switch v-model="page.is_active" name="check-button" label="Status">

                    </v-switch>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <v-btn
                    class="text-gray btn btn-standard cancel-btn"
                    depressed
                    @click="cancel"
                >Cancel
                </v-btn>
                <v-btn v-if="checkIsAccessible('page', 'create')"
                    class="text-white ml-2 btn btn-primary"
                    depressed
                    @click="saveOrUpdate"
                    :loading="isBusy"
                >Save
                </v-btn>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import PageService from "@/core/services/page/PageService";

const pageService = new PageService();

export default {
  validations: {
    page: {
      title: {required},
      content: {required}
    }
  },
  data() {
    return {
      edit: false,
      sizeExceed: false,
      isBusy: false,
      page: {
        title: null,
        slug: null,
        custom_slug: null,
        content: null,
        position: null,
        seo_title: null,
        seo_keyword: null,
        seo_description: null,
        social_share_image: null,
        social_share_description: null,
        views: null,
        is_active: true
      }
    };
  }, mounted() {
    this.page_id = this.$route.params.id;
    if (this.page_id) {
      this.edit = true;
      this.getPage(this.page_id);
    }
  },

  methods: {
    showModal(pageId = null) {
      if (pageId) {
        this.getPage(pageId);
      }

    },
    cancel() {
      this.$tabs.close();
     this.redirectTo();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    getPage(pageId) {
      pageService
          .show(pageId)
          .then(response => {
            this.page = response.data.page;
            if (this.page.seo_keyword ){
              this.page.seo_keyword = this.page.seo_keyword.split(',')
            }
          })
          .catch(error => {
            // console.log(error);
          });
    },
    saveOrUpdate() {
      this.$v.page.$touch();
      if (this.$v.page.$error) {
        setTimeout(() => {
          this.$v.page.$reset();
        }, 3000);
      } else {
        if (this.page.seo_keyword && this.page.seo_keyword.length){
          this.page.seo_keyword = this.page.seo_keyword.join(',')
        }
        this.page.id ? this.updatePage() : this.createPage();
      }
    },
    createOrUpdate() {
      this.$v.page.$touch();
      if (this.$v.page.$error) {
        setTimeout(() => {
          this.$v.page.$reset();
        }, 3000);
      } else {

        if (this.edit) {
          this.updatePage();
        } else {
          this.createPage();
        }
      }
    },
    redirectTo() {
      this.$router.push({
        name: "page",
      });
    },
    updatePage() {
      this.isBusy = true;
      pageService
          .update(this.page.id, this.page)
          .then(response => {
            this.isBusy = false;
            this.page.seo_keyword =[];
            this.$snotify.success("Information updated");
            this.resetForm();
            this.$v.page.$reset();
            this.$tabs.close().then(response => {
              this.$router.push({
                name: "page"
              });
            });
          })
          .catch(error => {
            this.$snotify.error("Something Went Wrong");
            this.isBusy = false;
            // console.log(error);
          });
    },
    createPage() {
      this.isBusy = true;
      pageService
          .store(this.page)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information added");
            this.resetForm();
            this.$v.page.$reset();
            this.$tabs.close().then(response => {
              this.$router.push({
                name: "page"
              });
            });
          })
          .catch(error => {
            this.$snotify.error("Something Went Wrong");
            this.isBusy = false;
            // console.log(error);
          });
    },
    resetForm() {
      this.edit = false
      this.page = {
        title: null,
        slug: null,
        custom_slug: null,
        content: null,
        position: null,
        seo_title: null,
        seo_keyword: null,
        seo_description: null,
        social_share_image: null,
        social_share_description: null,
        views: null,
        is_active: true
      };
      this.redirectTo();

    }
  }
};
</script>
